<template>
  <div class="formPart">
    <el-form :model="query" size="small" inline label-width="100px" label-position="right">
      <template v-for="{ label, placeholder, prop } in formColData">
        <el-col v-if="prop === 'back'" :key="prop" :span="2">
          <div class="backClass" @click="goBack" style="width: 100px">《 返回</div>
        </el-col>
        <el-col v-else-if="prop === 'createTime'" :span="8" :key="prop">
          <el-form-item :label="label">
            <SaleDateTime :time.sync="time" @change="toQuery" style="width: 400px" />
          </el-form-item>
        </el-col>
        <el-col v-else-if="prop === 'paymentTime'" :span="8" :key="prop">
          <el-form-item :label="label">
            <SaleDateTime :time.sync="time" @change="toQuery" style="width: 400px" />
          </el-form-item>
        </el-col>
        <el-col v-else-if="prop === 'paymentType'" :span="5" :key="prop">
          <el-form-item :label="label">
            <el-select v-model="query[prop]" placeholder="请选择" @change="toQuery" clearable>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-else-if="prop === 'priceStatus'" :span="19" :key="prop">
          <el-form-item :label="label">
            <div style="display: flex">
              <el-select v-model="query[prop]" placeholder="请选择" @change="toQuery" clearable>
                <el-option v-for="item in priceStatus" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-button class="el-icon-search" type="primary" @click="toQuery" style="margin-left: 20px"></el-button>
            </div>
          </el-form-item>
        </el-col>
        <el-col v-else-if="prop === 'button'" :span="1" :key="prop" class="button-col">
          <el-form-item :label="label">
            <el-button class="el-icon-search" type="primary" @click="toQuery"></el-button>
          </el-form-item>
        </el-col>
        <el-col v-else-if="prop === 'orderCodes'" :span="5" :key="prop">
          <el-form-item :label="label">
            <el-input :placeholder="placeholder" v-model="orderCodes" @change="mytoQuery" clearable></el-input>
          </el-form-item>
        </el-col>

        <el-col v-else :span="5" :key="prop">
          <el-form-item :label="label">
            <el-input :placeholder="placeholder" v-model="query[prop]" @change="toQuery" clearable></el-input>
          </el-form-item>
        </el-col>
      </template>
    </el-form>
  </div>
</template>

<script>
import SaleDateTime from '@/components/saleDateTime'
import CommonCascader from '@/components/CommonCascader.vue'
import { commonEheaderMixin } from '@/mixins'
export default {
  mixins: [commonEheaderMixin],
  components: {
    SaleDateTime,
    CommonCascader
  },
  props: {
    formColData: {
      type: Array,
      required: true
    },
    searchState: {
      type: Number
    }
  },
  data() {
    return {
      orderCodes: '',
      time: [],
      //paymentType 支付方式/支付渠道（ 1、支付宝 2、微信 3、银行卡、4，余额付款）
      options: [
        { label: '支付宝', value: 1 },
        { label: '微信', value: 2 },
        { label: '银行卡', value: 3 },
        { label: '余额付款', value: 4 }
      ],
      priceStatus: [
        {
          label: '正常',
          value: 1
        },
        {
          label: '异常',
          value: 0
        }
      ]
    }
  },
  computed: {
    formField() {
      switch (this.searchState) {
        case 0:
          return [{ prop: 'create_time' }]
        case 1:
          return [{ prop: 'payment_time' }]
        default:
          return
      }
    }
  },
  watch: {
    searchState() {
      this.time = []
    }
  },
  methods: {
    mytoQuery() {
      if (this.orderCodes === '') delete this.query.orderCodes
      else this.query.orderCodes = this.orderCodes.split(' ')
      this.toQuery()
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.formPart {
  overflow: hidden;
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 5px;
  .backClass {
    font-size: 24px;
    cursor: pointer;
  }
  .el-form-item--small {
    margin: 0 !important;
  }
}
</style>
